<template>
	<div class="o-wrap">
		<el-page-header @back="$router.go(-1)" content="发布案例"/>
		<CasesForm @eventForm="handleGetForm" class="purForm" />
	</div>
</template>

<script>
	import CasesForm from '@/components/Cases/form'
	export default {
		methods: {
			handleGetForm(form) {
				this.$axios.post('/addgcal', form).then(data => {
					this.$message.success('发布成功')
					this.$router.push('/cases')
				})
			}
		},
		components: {
			CasesForm
		}
	}
</script>
<style scoped>
	.purForm{
		width: 1510px;
		margin: 10px auto;
	}
</style>
